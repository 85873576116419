import React, { useEffect } from "react";
import PropTypes from "prop-types";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

const Confirmation = ({
  vehicleChoosedDetails,
  reservationDetails,
  checkInData,
  clientDetails,
  vehicleExtras,
  totalRentalValue,
  pickUpDesk,
  dropOffDesk
}) => {

  const { t } = useTranslation();
  useEffect(() => {
    let emailExtras = "";

    console.log(clientDetails, "clientDetails");

    vehicleExtras?.extraFees
      ?.filter((fee) => fee.quantity > 0)
      ?.map((fee) => (emailExtras += `\n${fee.extraName} -> ${fee.totalValue}€ \n`));

    const emailData = {
      idreserva: checkInData.idreserva,
      totalRentalValue: totalRentalValue,
      fullName: clientDetails.customer_name,
      email: clientDetails.customer_email,
      model: vehicleChoosedDetails.vehicleModel,
      group: vehicleChoosedDetails.vehicleCode,
      pickupPlace:
        pickUpDesk === 41
          ? "Balcão Funchal- Rua Ivens Nº12"
          : pickUpDesk === 42
          ? "Balcão Aeroporto da Madeira"
          : pickUpDesk === 47
          ? "Balcão Calheta"
          : pickUpDesk === 46
          ? "Balcão Norte"
          : "Unknown Location",
      returnPlace:
        pickUpDesk === 41
          ? "Balcão Funchal- Rua Ivens Nº12"
          : pickUpDesk === 42
          ? "Balcão Aeroporto da Madeira"
          : pickUpDesk === 47
          ? "Balcão Calheta"
          : pickUpDesk === 46
          ? "Balcão Norte"
          : "Unknown Location",
      pickupDate: reservationDetails.date_pickup,
      pickupTime: reservationDetails.time_pickup,
      returnDate: reservationDetails.date_return,
      returnTime: reservationDetails.time_return,
      totalDays: vehicleChoosedDetails.totalDays,
      extras: emailExtras,
      phoneNumber: clientDetails.customer_telephone,
      countryCode: clientDetails.countryCode
    };

    emailjs.send(
      "service_e7useue",
      "template_c722lsd",
      emailData,
      "05_Hr3dTwXkn7tQ9c"
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h1><b>{t("bookingConfirmed")}</b></h1>
      <p>{t("bookingSummary")}:</p>
      <ul>
        <li>{t("orderId")}: {checkInData.idreserva}</li>
        <li>
        {t("pickUp")}:{" "}
          {pickUpDesk === 41
            ? "Balcão Funchal- Rua Ivens Nº12"
            : pickUpDesk === 42
            ? "Balcão Aeroporto da Madeira"
            : pickUpDesk === 47
            ? "Balcão Calheta"
            : pickUpDesk === 46
            ? "Balcão Norte"
            : "Unknown Location"}
        </li>
        <li>{t("pickUpDate")}: {reservationDetails.date_pickup}</li>
        <li>{t("pickUpTime")}: {reservationDetails.time_pickup}</li>
        <li>
        {t("dropOff")}:{" "}
          {dropOffDesk === 41
            ? "Balcão Funchal- Rua Ivens Nº12"
            : dropOffDesk === 42
            ? "Balcão Aeroporto da Madeira"
            : dropOffDesk === 47
            ? "Balcão Calheta"
            : dropOffDesk === 46
            ? "Balcão Norte"
            : "Unknown Location"}
        </li>
        <li>{t("returnDate")}: {reservationDetails.date_return}</li>
        <li>{t("returnTime")}: {reservationDetails.time_return}</li>
        <li>{t("clientDetails")}:</li>
        <li>{t("fullName")}: {clientDetails.customer_name}</li>
        <li>{t("vehicleChoosed")}:</li>
        <li>{t("model")}: {vehicleChoosedDetails.vehicleModel}</li>
        <li>{t("group")}: {vehicleChoosedDetails.vehicleCode}</li>
        <li>{t("extrasChoosed")}:</li>
        {vehicleExtras?.extraFees
          ?.filter((fee) => fee.quantity > 0)
          ?.map((fee) => (
            <li>{fee.extraName}</li>
          ))}
         <li><b>{t("furtherQuestions")} </b></li>
         <li href={'mailto:front.office@whynotcarrental.com'}><b>{t("email")}:</b> reservations@whynotcarrental.com</li>
         <li target="_blank" href="tel:00351291213100"><b>{t("phone")}:</b> +351 291213100</li>
         <li>{t("bestRegards")}</li>
          <li>WhyNot Car Rental</li>
      </ul>
    </div>
  );
};

Confirmation.defaultProps = {
  vehicleChoosedDetails: PropTypes.any,
  reservationDetails: PropTypes.any,
  checkInData: PropTypes.any,
  clientDetails: PropTypes.any,
  vehicleExtras: PropTypes.any,
  totalRentalValue: PropTypes.any,
  pickUpDesk: PropTypes.number,
  dropOffDesk: PropTypes.number,
};

export default Confirmation;
