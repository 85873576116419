import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "pt",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        home: "Home",
        contacts: "Contacts",
        contactUs: "Contact-us",
        about: "About",
        fleet: "Fleet",
        address: "Address",
        phone: "Phone",
        informations: "Informations",
        termsAndConditions: "Terms & Conditions",
        privacyPolicy: "Privacy Policy",
        fullName: "Full Name",
        fullNameIsRequired: "Full name is required",
        fullNameInvalid: "Full name must have at least 3 characters",
        email: "Email",
        emailIsRequired: "Email is required",
        emailInvalid: "Email is not valid",
        phoneNumber: "Phone number",
        phoneNumberIsRequired: "Phone number is required",
        phoneNumberInvalid: "Phone number must have at least 9 characters",
        message: "Message",
        messageIsRequired: "Message is required",
        messageInvalid: "Message must have at least 3 characterss",
        submit: "Send",
        monday: "Monday",
        sunday: "Sundal",
        legal: "Legal",
        Portuguese: "Portuguese",
        Spanish: "Spanish",
        English: "English",
        German: "German",
        French: "French",
        emailSent: "Email sent successfully.",
        wipInfo:
          "Updates underway! Our reservation process is currently being improved for a better booking experience. Thank you for your patience.",
        deliveryDesk: "Delivery Place",
        returnDesk: "Return Place",
        deliveryDate: "Delivery Date",
        returnDate: "Return Date",
        deliveryTime: "Delivery Time",
        returnTime: "Return Time",
        hotel: "Hotel",
        flight: "Flight",
        rentalPeriod: "Rental Period",
        myVehicle: "My Vehicle",
        optionals: "Optionals",
        clientData: "Client data",
        payment: "Payment",
        confirmation: "Confirmation",
        pickUp: "Pick-Up",
        dropOff: "Drop-off",
        group: "Group",
        orSimilar: "Or Similiar",
        carDetails: "Car Details",
        fuelType: "Fuel Type",
        gearbox: "Gearbox",
        seats: "Seats",
        doors: "Doors",
        from: "From",
        day: "Day",
        reserve: "Reserve",
        noCarsAvaiable: "No cars avaiable for the requested dates.",
        myCar: "My Car",
        myReservation: "My Reservation",
        carPerDayValue: "Car per day value",
        per: "per",
        days: "days",
        extrasChoosed: "Extras choosed",
        extra: "Extra",
        cost: "Cost",
        totalPrice: "Total Price",
        continue: "Continue",
        clientDetails: "Client Details",
        birthDate: "Birth Date",
        city: "City",
        postalCode: "Postal Code",
        country: "Country",
        temporaryAddress: "Temporary Address",
        totalRentalPriceIs: "The total rental price is",
        bookingConfirmed: "Your Booking is Confirmed!",
        bookingSummary:
          "Thank you for your order! Here's a summary of your purchase",
        orderId: "Order ID",
        vehicleChoosed: "Vehicle Choosed",
        model: "Model",
        furtherQuestions: "For further questions, you can contact our team.",
        bestRegards: "Best Regards",
        search: "Search",
        about1:
          "M.I.Nunes, Unip., Lda., now under the commercial brand Why Not Car Rental, has more than forty years of experience in the market, and is represented in the center of Funchal, as well as 3 minutes from the airport. We have a diverse and very recent fleet of vehicles and scooters. If safety and comfort combined with quality and an affordable price are priorities for you, do not hesitate to contact a rental company recognized for its level of demand in terms of services and fleet.",
        about2:
          "At Why Not Car Rental, we believe that the freedom to explore shouldn't compromise your wallet. That's why we're committed to offering the best prices on the market. We work hard to ensure that our guests enjoy competitive rates, allowing them to make the most of their stay on the island of Madeira.",
        about3:
          "To make your experience even more enjoyable, Why Not Car Rental offers free airport transportation. Nothing is more reassuring than knowing that, after your trip, one of our professionals will be ready to take you back to the airport, ensuring a smooth transition between your arrival and departure.",
          ourHistory:"Our Story",
          promiseFulfilled:"A Promise Fulfilled",
          moreConvenienceLessStress:"More Convenience, Less Stress",
          chooseYourWay:"Choose your way",
          hotelDeliveryInfo:"In this desk we only deliver cars in Hotels! (A fee of delivery and pickup it will be applied 10 euros each way!)",
          airportDeliveryInfo:"Please Intruduce your flight Number",
          automatic:"Automatic",
          manual:"Manual",
          countryCode:"Country Code"
      }
      },
    pt: {
      translation: {
        home: "Home",
        contacts: "Contactos",
        contactUs: "Contacte-nos",
        about: "Sobre-nós",
        fleet: "Frota",
        address: "Morada",
        phone: "Telefone",
        informations: "Informações",
        termsAndConditions: "Termos e Condições",
        privacyPolicy: "Politica de Privacidade",
        fullName: "Nome Completo",
        fullNameIsRequired: "Nome é obrigatório",
        fullNameInvalid: "O nome deve ter pelo menos 3 caracteres",
        email: "Email",
        emailIsRequired: "Email é obrigatório",
        emailInvalid: "O Email não é valido",
        phoneNumber: "Número de telefone",
        phoneNumberIsRequired: "Número de telefone é obrigatório",
        phoneNumberInvalid:
          "O número de telefone deve ter pelo menos 9 caracteres",
        message: "Mensagem",
        messageIsRequired: "Mensagem é obrigatório",
        messageInvalid: "A mensagem deve ter pelo menos 3 caracteres",
        submit: "Enviar",
        monday: "Segunda-Feira",
        sunday: "Domingo",
        legal: "Legal",
        Portuguese: "Portugues",
        Spanish: "Espanhol",
        English: "Inglês",
        German: "Alemão",
        French: "Francês",
        emailSent: "Email enviado com sucesso.",
        wipInfo:
          "Atualizações em andamento! Nosso processo de reservas está a ser melhorado para uma melhor experiência de reserva. Obrigado pela sua paciência.",
        deliveryDesk: "Local de Entrega",
        returnDesk: "Local de Recolha",
        deliveryDate: "Data de Entrega",
        returnDate: "Data de Recolha",
        deliveryTime: "Hora de Entrega",
        returnTime: "Hora de recolha",
        hotel: "Hotel",
        flight: "Nº de Voo",
        rentalPeriod: "Período de Aluguer",
        myVehicle: "A minha viatura",
        optionals: "Opcionais",
        clientData: "Dados Cliente",
        payment: "Pagamento",
        confirmation: "Confirmação",
        pickUp: "Levantamento",
        dropOff: "Recolha",
        group: "Grupo",
        orSimilar: "Ou Similar",
        carDetails: "Detalhes da viatura",
        fuelType: "Combustível",
        gearbox: "Transmissão",
        seats: "Lugares",
        doors: "Portas",
        from: "Desde",
        day: "Dia",
        reserve: "Reservar",
        noCarsAvaiable: "Não há carros disponíveis para as datas selecionadas.",
        myCar: "A minha Viatura",
        myReservation: "A minha Reserva",
        carPerDayValue: "Tarifa diaria",
        per: "por",
        days: "dias",
        extrasChoosed: "Extras escolhidos",
        extra: "Extra",
        cost: "Custo",
        totalPrice: "Preço Total",
        continue: "Continuar",
        clientDetails: "Dados Cliente",
        birthDate: "Data de Nascimento",
        city: "Cidade",
        postalCode: "Codigo Postal",
        country: "País",
        temporaryAddress: "Endereço Temporário",
        totalRentalPriceIs: "O preço total do aluguer é",
        bookingConfirmed: "A sua reserva foi confirmada!",
        bookingSummary:
          "Obrigado pela sua reserva! Aqui está um resumo da sua reserva!",
        orderId: "ID da reserva",
        vehicleChoosed: "Viatura Escolhida",
        model: "Modelo",
        furtherQuestions:
          "Em caso de alguma dúvida, não hesite em contactar a nossa equipa.",
        bestRegards: "Com os melhores cumprimentos",
        search: "Pesquisar",
        about1:
          "A M.I.Nunes, Unip., Lda. agora com a marca comercial Why Not Car Rental, conta com mais de quarenta anos de experiência no mercado, e, está representada no centro do Funchal, bem como a 3 minutos do aeroporto. Dispomos de uma frota de viaturas e scooters diversificada e muito recente. Se a segurança e o conforto aliados à qualidade e a um preço acessível são prioridades para si, não hesite em contactar uma companhia de aluguer reconhecida pelo seu grau de exigência quer a nível dos serviços, quer da frota.",
        about2:
          "Na Why Not Car Rental, acreditamos que a liberdade de explorar não deve comprometer o seu bolso. É por isso que nos comprometemos a oferecer os melhores preços do mercado. Trabalhamos arduamente para garantir que os nossos clientes desfrutem de tarifas competitivas, permitindo-lhes tirar o máximo proveito da sua estadia na ilha da Madeira.",
        about3:
          "Para tornar a sua experiência ainda mais agradável, a Why Not Car Rental oferece transporte gratuito para o aeroporto. Nada é mais reconfortante do que saber que, após a sua viagem, um dos nossos profissionais estará pronto para o levar de volta ao aeroporto, garantindo uma transição suave entre a sua chegada e partida.",
          ourHistory:"A nossa história",
          promiseFulfilled:"Uma promessa cumprida",
          moreConvenienceLessStress:"Mais conveniência, menos stress",
          chooseYourWay:"Escolha a sua medida",
          hotelDeliveryInfo:"Neste balcão é apenas possível entregar as viaturas em hoteis!(Uma taxa de entrega e de recolha no valor de 10 euros cada será aplicada)",
          airportDeliveryInfo:"Por favor introduza o numero de Voo",
          automatic:"Automático",
          manual:"Manual",
          countryCode:"Indicativo"
      },
    },
    // es: {
    //   translation: {
    //     home: "Casa",
    //     contacts: "Contactos",
    //     about: "Acerca de",
    //     fleet: "Flota",
    //     address: "Dirección",
    //     phone: "Teléfono",
    //     informations:"Informaciones",
    //     termsAndConditions: "Términos y Condiciones",
    //     privacyPolicy: "Política de privacidad",
    //     fullName:"Nombre completo",
    //     fullNameIsRequired:"El nombre es obligatorio",
    //     fullNameInvalid:"El nombre debe tener al menos 3 caracteres.",
    //     email:"Email",
    //     emailIsRequired:"El correo electrónico es obligatorio",
    //     emailInvalid:"El correo electrónico no es válido.",
    //     phoneNumber:"Número de teléfono",
    //     phoneNumberIsRequired:"Se requiere número de teléfono",
    //     phoneNumberInvalid:"El número de teléfono debe tener al menos 9 caracteres.",
    //     message:"Mensaje",
    //     messageIsRequired:"El mensaje es obligatorio",
    //     messageInvalid:"El mensaje debe tener al menos 3 caracteres.",
    //     submit:"Mandar",
    //     monday:"Lunes",
    //     sunday:"Domingo",
    //     legal:"Fresco",
    //     Portuguese: "portugués",
    //     Spanish: "Español",
    //     English: "Inglés",
    //     German: "Alemán",
    //     French: "Francés",
    //     emailSent:"Email enviado correctamente."
    //   },
    // },
    // de: {
    //   translation: {
    //     home: "Home",
    //     contacts: "Contactos",
    //     about: "Sobre-nós",
    //     fleet: "Frota",
    //     address: "Morada",
    //     phone: "Telefone",
    //     informations:"Informações",
    //     termsAndConditions: "Termos e Condições",
    //     privacyPolicy: "Politica de Privacidade",
    //     fullName:"Nome Completo",
    //     fullNameIsRequired:"Nome é obrigatório",
    //     fullNameInvalid:"O nome deve ter pelo menos 3 caracteres",
    //     email:"Email",
    //     emailIsRequired:"email é obrigatório",
    //     emailInvalid:"O email não é valido",
    //     phoneNumber:"Número de telefone",
    //     phoneNumberIsRequired:"número de telefone é obrigatório",
    //     phoneNumberInvalid:"O número de telefone deve ter pelo menos 9 caracteres",
    //     message:"Mensagem",
    //     messageIsRequired:"Mensagem é obrigatório",
    //     messageInvalid:"A mensagem deve ter pelo menos 3 caracteres",
    //     submit:"Enviar",
    //     monday:"Segunda-Feira",
    //     sunday:"Domingo",
    //     legal:"Legal",
    //     Portuguese: "Portugues",
    //     Spanish: "Espanhol",
    //     English: "Inglês",
    //     German: "Alemão",
    //     French: "Francês",
    //   },
    // },
    // fr: {
    //   translation: {
    //     home: "Home",
    //     contacts: "Contactos",
    //     about: "Sobre-nós",
    //     fleet: "Frota",
    //     address: "Morada",
    //     phone: "Telefone",
    //     informations:"Informações",
    //     termsAndConditions: "Termos e Condições",
    //     privacyPolicy: "Politica de Privacidade",
    //     fullName:"Nome Completo",
    //     fullNameIsRequired:"Nome é obrigatório",
    //     fullNameInvalid:"O nome deve ter pelo menos 3 caracteres",
    //     email:"Email",
    //     emailIsRequired:"email é obrigatório",
    //     emailInvalid:"O email não é valido",
    //     phoneNumber:"Número de telefone",
    //     phoneNumberIsRequired:"número de telefone é obrigatório",
    //     phoneNumberInvalid:"O número de telefone deve ter pelo menos 9 caracteres",
    //     message:"Mensagem",
    //     messageIsRequired:"Mensagem é obrigatório",
    //     messageInvalid:"A mensagem deve ter pelo menos 3 caracteres",
    //     submit:"Enviar",
    //     monday:"Segunda-Feira",
    //     sunday:"Domingo",
    //     legal:"Legal",
    //     Portuguese: "Portugues",
    //     Spanish: "Espanhol",
    //     English: "Inglês",
    //     German: "Alemão",
    //     French: "Francês",
    //   },
    // },
  }
});

export default i18n;
