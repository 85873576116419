import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Container, Stack, Typography } from "@mui/material";
import Optionals from "./Optionals";
import ChooseVehicle from "./ChooseVehicle";
import { useLocation } from "react-router-dom";
import ClientForm from "./ClientForm";
import Payment from "./Payment";
import Confirmation from "./Confirmation";
const theme = createTheme({
  palette: {
    primary: {
      main: "#263677",
    },
    secondary: {
      main: "#EE8013",
    },
    danger: "#fa0505",
  },
});

function ReservationCheckout() {
  const { t } = useTranslation();
  const reservationData = useLocation();
  const reservationInfo = reservationData.state;
  const vehicleAvaibility = reservationInfo?.avaiabilities;
  const reservationDetails = reservationInfo?.reservationDetails;
  const [wasCarChoosed, setWasCarChoosed] = useState(false);
  const [wasOptionalsChoosed, setWasOptionalsChoosed] = useState(false);
  const [wasClientDetailsFiled, setWasClientDetailsFiled] = useState(false);
  const [vehicleChoosedDetails, setVehicleChoosedDetails] = useState(false);
  const [vehicleExtras, setVehicleExtras] = useState(null);
  const [totalRentalValue, setTotalRentalValue] = useState(0);
  const [clientDetails, setClientDetails] = useState(null);
  const [reservationFinalData, setReservationFinalData] = useState(null);
  const [paymentAproved, setPaymentAproved] = useState(false);
  const [paypalOrderId, setPaypalOrderId] = useState("");
  const [checkInData, setCheckInData] = useState("");
  const [reservationConfirmed, setReservationConfirmed] = useState(false);

  useEffect(() => {
    console.log(reservationData, "reservationData");
    console.log(reservationInfo, "reservationInfo");
  }, [reservationData, reservationInfo]);

  useEffect(() => {
    if (paymentAproved) {
      console.log("payment aproved");
      const itemName = "itemName";
      const itemPrice = "itemPrice";
      const itemNameKey = `${itemName}[${vehicleChoosedDetails.vehicleCode}]`;
      const itemPriceKey = `${itemPrice}[${vehicleChoosedDetails.vehicleCode}]`;

      const equipment = {};

      vehicleExtras?.extraEquipments?.forEach((item) => {
        const { Equipment, Charge } = item;
        const { Description, EquipType } = Equipment;
        const { Amount, IncludedInRate } = Charge;
        const itemName = `itemName[${EquipType}]`;
        const itemPrice = `itemPrice[${EquipType}]`;
        const pricedEquips = `pricedEquips[${EquipType}]`;
        equipment[itemName] = Description;
        equipment[itemPrice] = Amount;
        equipment[pricedEquips] = IncludedInRate;
      });

      const coverages = {};
      vehicleExtras?.extraCoverages?.forEach((item) => {
        const { Coverage, Charge } = item;
        const { CoverageType } = Coverage;
        const { IncludedInRate } = Charge;
        const coverage = `coverages[${CoverageType}]`;
        coverages[coverage] = IncludedInRate;
      });

      console.log(coverages, "coverages");
      console.log(equipment, "equipment");

      const data = {
        ...reservationDetails,
        loc_pickup: reservationInfo.pickUpDesk,
        loc_return: reservationInfo.dropOffDesk,
        SIPP: vehicleChoosedDetails.vehicleCode,
        [itemNameKey]: vehicleChoosedDetails.vehicleModel,
        [itemPriceKey]: vehicleChoosedDetails.totalPrice,
        ...clientDetails,
      };

      setReservationFinalData(data);

      submitData(data);
    }
  }, [wasClientDetailsFiled, paymentAproved]);

  const submitData = async (data) => {
    console.log(data, "data");
    try {
      const response = await fetch(
        "https://www.whynotcarrental.com/Backend/reservationSub.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        // Wait for the response to be parsed as JSON
        console.log(response, "response");
        const reservationSubmited = await response.json();

        setReservationConfirmed(true);

        // Handle the response here
        console.log("reservationSubmited", reservationSubmited);
        const checkData = {
          idreserva:
            reservationSubmited.VehResRSCore.VehReservation.VehSegmentCore
              .ConfID.ID,
          customer_name:
            reservationSubmited.VehResRSCore.VehReservation.Customer.Primary
              .PersonName.GivenName,
          approvalCode: paypalOrderId,
          amount: totalRentalValue,
          rph: "PayPal",
        };
        setCheckInData(checkData);

        console.log(checkInData, "checkInData");
        //check in request
        try {
          const response = await fetch(
            "https://www.whynotcarrental.com/Backend/reservationCheckIn.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: JSON.stringify(checkData),
            }
          );

          if (response.ok) {
            // Wait for the response to be parsed as JSON
            console.log(response, "response checkin");
            const reservationCheckInSubmited = await response.json();
            console.log(reservationCheckInSubmited);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack
        spacing={2}
        alignItems={"center"}
        sx={{
          backgroundColor: "primary.main",
          textAlign: "center",
        }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="large" />}
          aria-label="breadcrumb"
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "white",
              textAlign: "center",
            }}
          >
            <Link
              to="/"
              underline="hover"
              color="inherit"
              state={vehicleAvaibility}
            >
              {t("rentalPeriod")}
            </Link>
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "white",
              textAlign: "center",
            }}
          >
            <Link
              onClick={() =>
                wasOptionalsChoosed ? null : setWasCarChoosed(false)
              }
              underline="hover"
              color="inherit"
              aria-current="page"
              state={reservationInfo}
            >
              {t("myVehicle")}
            </Link>
          </Typography>
          {wasCarChoosed && (
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: "white",
                textAlign: "center",
              }}
            >
              <Link
                onClick={() => setWasOptionalsChoosed(false)}
                underline="hover"
                color="inherit"
                aria-current="page"
                state={reservationInfo}
              >
                {t("optionals")}
              </Link>
            </Typography>
          )}
          {wasOptionalsChoosed && (
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: "white",
                textAlign: "center",
              }}
            >
              <Link
                underline="hover"
                color="inherit"
                aria-current="page"
                state={reservationInfo}
                onClick={() => setWasClientDetailsFiled(false)}
              >
                {t("clientData")}
              </Link>
            </Typography>
          )}
          {wasClientDetailsFiled && (
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: "white",
                textAlign: "center",
              }}
            >
              <Link
                underline="hover"
                color="inherit"
                aria-current="page"
                state={reservationInfo}
              >
                {t("payment")}
              </Link>
            </Typography>
          )}
          {reservationConfirmed && (
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: "white",
                textAlign: "center",
              }}
            >
              <Link
                underline="hover"
                color="inherit"
                aria-current="page"
                state={reservationInfo}
              >
                {t("confirmation")}
              </Link>
            </Typography>
          )}
        </Breadcrumbs>
      </Stack>
      <Container>
        {reservationConfirmed ? (
          <Confirmation
            checkInData={checkInData}
            reservationDetails={reservationDetails}
            vehicleChoosedDetails={vehicleChoosedDetails}
            vehicleExtras={vehicleExtras}
            clientDetails={clientDetails}
            totalRentalValue={totalRentalValue}
            pickUpDesk={reservationInfo.pickUpDesk}
            dropOffDesk={reservationInfo.dropOffDesk}
          />
        ) : wasClientDetailsFiled ? (
          <div>
            <Payment
              vehicleChoosedDetails={vehicleChoosedDetails}
              reservationInfo={reservationInfo.reservationDetails}
              clientDetails={clientDetails}
              setPaymentAproved={setPaymentAproved}
              vehicleExtras={vehicleExtras}
              totalRentalValue={totalRentalValue}
              paymentAproved={paymentAproved}
              pickUpDesk={reservationInfo.pickUpDesk}
              dropOffDesk={reservationInfo.dropOffDesk}
              setPaypalOrderId={
                setPaypalOrderId
              } /*bookingNumber={bookingNumber}*/
            />
          </div>
        ) : wasOptionalsChoosed ? (
          <div>
            <ClientForm
              reservationInfo={reservationDetails}
              setWasClientDetailsFiled={setWasClientDetailsFiled}
              setClientDetails={setClientDetails}
            />
          </div>
        ) : wasCarChoosed ? (
          <div id="vehicleChoosed">
            <Optionals
              vehicleChoosedDetails={vehicleChoosedDetails}
              reservationInfo={reservationInfo.reservationDetails}
              setVehicleExtras={setVehicleExtras}
              setWasOptionalsChoosed={setWasOptionalsChoosed}
              setTotalRentalValue={setTotalRentalValue}
              pickUpDesk={reservationInfo.pickUpDesk}
              dropOffDesk={reservationInfo.dropOffDesk}
            />
          </div>
        ) : (
          <ChooseVehicle
            reservationInfo={reservationInfo.reservationDetails}
            avaiabilities={vehicleAvaibility}
            setWasCarChoosed={setWasCarChoosed}
            setVehicleChoosedDetails={setVehicleChoosedDetails}
            pickUpDesk={reservationInfo.pickUpDesk}
            dropOffDesk={reservationInfo.dropOffDesk}
          />
        )}
      </Container>
    </ThemeProvider>
  );
}
export default ReservationCheckout;
