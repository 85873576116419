import React from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PropTypes from "prop-types";
import {
  Card,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { NumberConverter } from "../helpers/numberConverter";
import opelAstra from "../Assets/img_car/detail/18.opel_astra.png";
import peugeot308 from "../Assets/img_car/detail/peugeot308.png";
import PayPalCheckout from "./PayPalCheckout";
import { useTranslation } from "react-i18next";

function Payment({
  vehicleChoosedDetails,
  reservationInfo,
  clientDetails,
  setPaymentAproved,
  vehicleExtras,
  totalRentalValue,
  paymentAproved,
  setPaypalOrderId,
  pickUpDesk,
  dropOffDesk,
}) {
  const initialOptions = {
    "client-id":
      "AXVO04mtl9_KdA9UXQ5h7mqCgFfkk448HYka-rnBgbkjdJ4_Vo-86sBVG2hfVE7UIP8-maVSEJJ2HgFD",
    currency: "EUR",
    intent: "capture",
  };

  const { t } = useTranslation();

  const onApprove = (data, actions) => {
    // Handle the payment approval here, like updating the UI or saving the order details.
    return actions.order.capture().then(function (details) {
      console.log(details);
      setPaypalOrderId(data.orderID);
      setPaymentAproved(true);
    });
  };

  const onError = (err) => {
    console.log("Error:", err);
    // Handle the error here, like showing an error message to the user.
  };

  return (
    <div className="Payment">
      <Typography variant="h4" component="div">
        {t("payment")}
      </Typography>
      <Typography variant="h5" component="div">
        {t("totalRentalPriceIs")} {totalRentalValue} euros
      </Typography>
      <>
        <div className="container">
          <div className="car-details">
            <Card>
              <Typography
                gutterBottom
                sx={{ backgroundColor: "primary.main" }}
                variant="h5"
                align="center"
                color="white"
                component="div"
              >
                {t("myVehicle")}
              </Typography>
              <Typography
                gutterBottom
                variant="h5"
                align="center"
                color="primary.main"
                component="div"
              >
                {t("group")} {vehicleChoosedDetails.vehicleCode}
              </Typography>
              <Typography
                gutterBottom
                variant="h6"
                align="center"
                component="div"
              >
                {vehicleChoosedDetails.vehicleModel} {t("orSimilar")}
              </Typography>
              <CardMedia
                sx={{ margin: "20px", height: 180, width: 300 }}
                image={
                  vehicleChoosedDetails.pictureUrl
                    ? process.env.PUBLIC_URL +
                      "img_car/detail/" +
                      vehicleChoosedDetails.pictureUrl
                    : vehicleChoosedDetails.vehicleCode === "CDAV"
                    ? opelAstra
                    : vehicleChoosedDetails.vehicleCode === "CDAD"
                    ? peugeot308
                    : null
                }
                title="CAR"
              />
              <Typography
                sx={{ backgroundColor: "primary.main" }}
                gutterBottom
                variant="h5"
                align="center"
                color="white"
                component="div"
              >
                {t("myReservation")}
              </Typography>
              <>
                <Typography
                  gutterBottom
                  variant="h6"
                  align="center"
                  component="div"
                >
                  {t("pickUp")}
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  align="center"
                  component="div"
                >
                  {pickUpDesk === 41
                    ? "Balcão Funchal- Rua Ivens Nº12"
                    : pickUpDesk === 42
                    ? "Balcão Aeroporto da Madeira"
                    : pickUpDesk === 47
                    ? "Balcão Calheta"
                    : pickUpDesk === 46
                    ? "Balcão Norte"
                    : "Unknown Location"}
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  align="center"
                  component="div"
                >
                  {dayjs(reservationInfo?.date_pickup).format("DD/MM/YYYY")} às{" "}
                  {reservationInfo?.time_pickup}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  align="center"
                  component="div"
                >
                  {t("dropOff")}
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  align="center"
                  component="div"
                >
                  {dropOffDesk === 41
                    ? "Balcão Funchal- Rua Ivens Nº12"
                    : dropOffDesk === 42
                    ? "Balcão Aeroporto da Madeira"
                    : dropOffDesk === 47
                    ? "Balcão Calheta"
                    : dropOffDesk === 46
                    ? "Balcão Norte"
                    : "Unknown Location"}
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  align="center"
                  component="div"
                >
                  {dayjs(reservationInfo?.date_return).format("DD/MM/YYYY")} às{" "}
                  {reservationInfo?.time_return}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  align="center"
                  component="div"
                >
                  {t("carPerDayValue")}
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  align="center"
                  component="div"
                >
                  <NumberConverter number={vehicleChoosedDetails.totalPrice} />€
                  {t("per")} {vehicleChoosedDetails.totalDays} {t("days")}
                </Typography>
                <Typography
                  sx={{ backgroundColor: "primary.main" }}
                  gutterBottom
                  variant="h5"
                  align="center"
                  color="white"
                  component="div"
                >
                  {t("extrasChoosed")}
                </Typography>
                <TableContainer>
                  <Table sx={{ minWidth: 200 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("extra")}</TableCell>
                        <TableCell align="right">{t("cost")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {vehicleExtras?.extraFees?.map(
                          ({
                            index,
                            extraName,
                            quantity,
                            value,
                            totalValue,
                            maxValue,
                          }) => (
                            <>
                              {quantity > 0 ? (
                                <>
                                  <TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {extraName} X{quantity}
                                    </TableCell>
                                    <TableCell align="right">
                                      {totalValue}€
                                    </TableCell>
                                  </TableRow>
                                </>
                              ) : null}
                            </>
                          )
                        )}
                      </>
                      <>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {t("totalPrice")}
                          </TableCell>
                          <TableCell align="right">
                            {parseFloat(totalRentalValue)}€
                          </TableCell>
                        </TableRow>
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
                <PayPalScriptProvider options={initialOptions}>
                  <div>
                    <PayPalCheckout
                      amount={totalRentalValue}
                      currency={"EUR"}
                      description={"vehicleBooking"}
                      onApprove={onApprove}
                      onError={onError}
                    />
                  </div>
                </PayPalScriptProvider>
              </>
            </Card>
          </div>
        </div>
      </>
    </div>
  );
}

Payment.defaultProps = {
  vehicleChoosedDetails: PropTypes.any,
  reservationInfo: PropTypes.any,
  clientDetails: PropTypes.any,
  setPaymentAproved: PropTypes.any,
  vehicleExtras: PropTypes.any,
  totalRentalValue: PropTypes.any,
  paymentAproved: PropTypes.any,
  setPaypalOrderId: PropTypes.any,
  //bookingNumber:PropTypes.any,
  pickUpDesk: PropTypes.number,
  dropOffDesk: PropTypes.number,
};

export default Payment;
